@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Noto+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
:root{
  --gray: #333333;
  --greyC: #cccccc;
  --primary: #06ABB6;
  --secondary: #fcf5e7;
}
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--gray);
  font-size: 16px;
}
h1,h2,h3,h4,h5,h6{
  font-weight: 500;
}
h4, h5{
  font-size: 18px;
}
a{
  text-decoration: none;
  color: var(--gray);
}
a:hover{
  text-decoration: underline;
  color: var(--primary);
}
.readMore{
  display: inline-block;
  color: var(--primary);
}
.readMore:after{
  content: '';
  height: 3px;
  width: 0;
  background-color: var(--primary);
  display: block;
  transition: width .5s;
}
.readMore:hover:after{
  width: 100%;
}
.readMore:hover{
  text-decoration: none;
}
img{
  max-width: 100%;
}

/* bootstrap */
  /* forms */
  .form-control, .form-select{height: 50px;border-color: var(--greyC);padding: 13px;}
  .form-control:focus, .form-control:active, .form-select:focus, .form-select:active{outline: none;box-shadow: none;border-color: var(--greyC);}
  textarea.form-control{height: auto;}
  input[type=file].form-control{padding: 1px 12px;}
  input[type=file].form-control::file-selector-button {border: none;height: 52px;line-height: 45px;background-color: var(--primary);transition: 1s;color: #ffffff;}
  /* buttons */
  .btn{font-weight: 500;}
  .btn-primary{background-color: var(--primary);border-color: var(--primary);}
  .btn-primary:hover{background-color: var(--secondary);border-color: var(--secondary);text-decoration: none;color: var(--primary);}
  .btn-outline-primary{color: var(--primary);border-color: var(--primary);}
  .btn-outline-primary:hover{background-color: var(--secondary);border-color: var(--secondary);color: var(--gray);}
  .btn-group-lg>.btn, .btn-lg{padding: .5rem 1.7rem;}
  /* accordion */
  .accTra .accordion-item{background-color: transparent;border-width: 0 0 1px 0;border-bottom-color: var(--primary);}
  .accTra .accordion-button{background-color: transparent;padding-left: 0;padding-right: 0;font-family: 'Noto Serif';font-style: italic;}
  .accTra .accordion-item:last-of-type .accordion-button.collapsed,
  .accTra .accordion-item:last-of-type{border-radius: 0;}
  .accordion-button:not(.collapsed){color: var(--primary);}
  .accordion-button:focus{border-color: var(--primary); box-shadow: none; }
  .accordion-body{padding-left: 0; }
  .accordion-button:not(.collapsed)::after{background-image: none; content: '+'; }
  .accordion-button::after{background-image: none; font-family: 'Inter', sans-serif; content: '-'; font-size: 41px; font-style: normal; line-height: .4; }
  /* menu */
  .dropdown-item:focus, .dropdown-item:hover{text-decoration: none;background-color: var(--secondary);}
  .dropdown-item.active, .dropdown-item:active{color: var(--primary);background-color: var(--secondary);}
/* Utlilities */
  /* title */
  .ttlSec{margin-bottom: 15px;font-family: 'Noto Serif';font-style: italic;}
  .ttlSec:after{content:'';height: 3px;background-color: var(--primary);width: 70px;display: block;margin-top: 10px;}
  .ttl{font-family: 'Noto Serif';font-style: italic;margin-bottom: 35px;font-size: 30px;line-height: 1.4;}
  .ttl span, .ttlMain span{color: var(--primary);}
  .ttl:after{content:'';height: 5px;background-color: var(--primary);width: 110px;display: block;margin-top: 15px;}
  .ttlMain{font-size: 40px; font-family: 'Noto Serif'; font-style: italic; text-align: center; margin-bottom: 50px;}
  .ttlMain:after{content:'';height: 5px;background-color: var(--primary);width: 170px;display: block;margin: auto;margin-top: 15px;}
  /* video */
  .youtube16{position: relative;width: 100%;padding-bottom: 56.25%;}
  .youtube16 iframe{position: absolute;top: 0;left: 0;width: 100%;height: 100%;border: 0;}
  /* list */
  .listFontawesome{list-style: none;padding-left: 0;margin-bottom: 0;}
  .listFontawesome svg{color: var(--primary);padding-right: 8px;padding-top: 4px;}
  .listFontawesome li{margin-bottom: 5px;display: flex;}
  /* visdibility */
  .showMobile{display: none;}

/*slick slider*/
  .slick-next{
    right: 0;
  }
  .slick-initialized .slick-current{
    z-index: 99;
  }
  .slick-dots li.slick-active button:before,
  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before{
    color: var(--primary);
  }
  .slick-dots li button:before{
    font-size: 15px;
    color: var(--primary);
  }
  .slick-dots{
    bottom: 15px;
    z-index: 9;
  }

/* topbar */
  .topBar{
    background-color: var(--gray);
    color: #ffffff;
  }
  .topBar .container{
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: space-between;
    padding: 7px 0;
  }
  .topBar svg{
    margin-right: 5px;
    color: var(--secondary);
  }
  .topBar p,
  .topBar h5{
    margin: 0;
    font-size: 14px;
  }
  .topBar a{
    color: #ffffff;
  }

/*header*/
  .dropdown-menu[data-bs-popper]{
    display: block;
    opacity: 0;
    z-index: -1;
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transform: translateY(10%);
    -moz-transform: translateY(10%);
    -ms-transform: translateY(10%);
    transform: translateY(10%);
    transition: all 400ms ease;
    border: none;
    min-width: 210px;
  }
  .dropdown-menu.show,
  .nav-item.dropdown:hover .dropdown-menu{
    opacity: 1;
    z-index: 99;
    transform: translateY(0%);
    margin-top: 0;
    border-radius: 0;
    padding: 0;
  }
  .navbar-light .navbar-nav .nav-link{
    font-weight: 500;
    padding: 29px 15px;
    color: var(--gray);
  }
  .navbar-light .navbar-nav .nav-link:hover{
    color: var(--primary);
  }
  .navbar-brand img{
    height: 67px;
    margin-right: 30px;
  }
  .navbar{
    padding: 0;
  }
  .dropdown-toggle::after{
    display: none;
  }
  .dropdown-item{
    color: var(--gray);
    padding: 10px 15px;
    border-bottom: 1px solid #eeeeee;
  }
  .dropdown-menu .dropdown-item:last-child{
    border-bottom: 0;
  }
  .nbRight{
    margin-left: auto;
  }

/* banner */
  .mainBanner .mbBgIma{
    background-attachment: fixed;
    background-size: cover;
    height: 650px;
    position: relative;
  }
  .mainBanner .mbBgIma:before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.3);
  }
  .mainBanner::after{
    content: '';
    background-image: url(./images/bannerWave.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    width: 100%;
    height: 155px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .mainBanner .mbiCon{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: relative;
    width: 1320px;
    padding: 0 15px;
    max-width: 100%;
  }
  .mainBanner .mbiCon{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
  }
  .mainBanner .mbiCon h2{
    font-size: 55px;
    text-shadow: 0 2px 3px var(--gray);
    margin-bottom: 25px;
    font-family: 'Noto Serif', serif;
    font-weight: 400;
    font-style: italic;
  }
  .mainBanner .mbiCon h5{
    margin-bottom: 35px;
  }
  .mainBanner .btn{
    margin: 0 12px;
  }
  .mainBanner .mbiBtns{
    margin-left: -12px;
    margin-right: -12px;
  }

/* index */
  .ourSerInd{
    background-color: var(--secondary);
    margin-top: -7px;
    padding: 110px 0 75px;
  }
  .ourSerInd .row{
    justify-content: center;
  }
  .ourSerInd .row > div{
    margin-bottom: 35px;
  }
  .icoTtlBlo{
    background-color: #ffffff;
    padding: 25px 15px;
    text-align: center;
    border-radius: 11px;
  }
  .icoTtlBlo .itbIcon{
    width: 85px;
    height: 80px;
    margin: 0 auto 31px;
    background-image: url('images/icons.png');
  }
  .icoTtlBlo .itb1{
    background-position: -12px -14px;
  }
  .icoTtlBlo .itb2{
    background-position: -123px -14px;
  }
  .icoTtlBlo .itb3{
    background-position: -235px -14px;
  }
  .icoTtlBlo .itb4{
    background-position: -343px -14px;
  }
  .icoTtlBlo .itb5{
    background-position: -449px -14px;
  }
  .icoTtlBlo .itb6{
    background-position: -12px -126px;
  }
  .icoTtlBlo .itb7{
    background-position: -123px -126px;
  }
  .icoTtlBlo .itb8{
    background-position: -235px -126px;
  }
  .icoTtlBlo h4{
    margin-bottom: 15px;
  }
  .icoTtlBlo a{
    color: var(--primary);
  }
  .docDetThe{
    margin-top: 110px;
    margin-bottom: 110px;
  }
  .docDetThe .ddtDoc img{
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 1px 8px var(--secondary);
    margin-bottom: 25px;
  }
  .ddtDoc .ddList{
    list-style: none;
    padding-left: 0;
  }
  .ddtDoc .ddList li{
    margin-bottom: 7px;
    display: flex;
  }
  .ddtDoc .ddList svg{
    color: var(--primary);
    padding-right: 8px;
    padding-top: 4px;
  }
  .ddtDoc .ttlSec{
    font-size: 23px;
    margin-bottom: 25px;
  }
  .docDetThe .ddtCon p{
    text-align: justify;
  }
  .accConAdd{
    padding-top: 110px;
    background-image: url(./images/doctor-gc1bb32613_1920.jpg);
    background-size: cover;
    background-attachment: fixed;
  }
  .accConAdd:after{
    content: '';
    background-image: url(./images/bannerWaveW.png);
    background-repeat: no-repeat;
    background-position: bottom center;
    width: 100%;
    display: block;
    padding-top: 155px;
  }
  .accConAdd ul{
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    font-size: 17px;
    font-weight: 500;
  }
  .accConAdd ul li{
    margin-bottom: 5px;
  }
  .accConAdd ul svg{
    margin-right: 7px;
  }
  .accConAdd .accordion-button{
    font-size: 19px;
  }
  .tesSlick{
    margin-top: 110px;
    margin-bottom: 110px;
  }
  .tesSlick .tsSlider{
    display: flex;
  }
  .tesSlick .tsSlider img{
    width: 47%;
    height: 331px;
    object-fit: cover;
    margin-right: 3%;
    border-radius: 7px;
    box-shadow: 0px 1px 8px var(--secondary);
  }
  .tsSlider .mbiCon p{
    text-align: justify;
  }
  .tesSlick .testimanialSli{
    margin-bottom: 90px;
  }
  .ourInno{
    background-color: var(--primary);
    color: #ffffff;
    padding: 110px;
    text-align: center;
    font-family: 'Noto Serif';
    font-style: italic;
    font-size: 18px;
    line-height: 1.9;
  }
  .ourInno .ttlMain:after{
    background-color: var(--secondary);
  }
  .gooMap{
    position: relative;
  }
  .gooMap:after{
    content: '';
    display: block;
    background-image: url(./images/bannerWave.png);
    background-size: 100% 100%;
    background-position: center bottom;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 137px;
  }

/* About Us */
  .aboUs .auAbout{
    text-align: center;
    margin: 110px auto;
  }
  .aboUs .auDocCon{
    padding: 110px 0;
    color: var(--primary);
    background-size: cover;
    background-attachment: fixed;
  }
  .aboUs .auDocCon .row{
    align-items: center;
    justify-content: center;
  }
  .aboUs .ddtDoc .ddList{
    font-weight: 600;
    letter-spacing: 1px;
  }
  .aboUs .auVisMis{
    margin-top: 90px;
    background-color: var(--primary);
    color: #ffffff;
    padding: 25px;
    border-radius: 7px;
    box-shadow: 1px 3px 2px var(--primary);
  }
  .aboUs .auVisMis p{
    margin-bottom: 0;
  }
  .aboUs .ttlSec:after{
    background-color: var(--secondary);
  }

/* Services */
  .serPage{
    margin: 65px auto 110px;
  }
  .serPage .ttl{
    margin-top: 45px;
  }

/* inner banner */
  .innBanner{
    text-align: center;
    background-attachment: fixed;
    height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #ffffff;
    position: relative;
    background-size: cover;
    background-position: center top;
  }
  .innBanner h2{
    font-size: 55px;
    text-shadow: 0 2px 3px var(--gray);
    margin-bottom: 25px;
    font-family: 'Noto Serif', serif;
    font-weight: 400;
    font-style: italic;
    max-width: 70%;
    line-height: 1.5;
  }
  .innBanner h5{
    margin-bottom: 35px;
  }
  .innBanner:before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.3);
  }
  .innBanner > *{
    position: relative;
  }

/* Events */
  .evePagCon .innBanner{
    margin-bottom: 110px;
  }
  .evePagCon .epcEve{
    margin-bottom: 35px;
    padding: 0 15px;
  }

/* PhotoGallery, VideoGallery */
  .phoGalPag .innBanner,
  .phoGalPag{
    margin-bottom: 110px;
  }
  .phoGalPag .pgpGal{
    margin-bottom: 16px;
    padding: 0 8px;
  }
  .phoGalPag .pgpGal img{
    box-shadow: 0px 0px 5px var(--primary);
    border: 2px solid var(--primary);
    border-radius: 11px;
    padding: 2px;
    background-color: var(--secondary);
  }
  .phoGalPag .pgpVid{
    margin-bottom: 110px;
  }
  .phoGalPag .youtube16{
    margin-bottom: 24px;
  }

/* Parential Education */
  .parEduPag .innBanner{
    margin-bottom: 110px;
  }
  .parEduPag{
    margin-bottom: 65px;
  }
  .parEduPag .pmCon{
    display: inline-block;
    margin-bottom: 35px;
  }
  .parEduPag .pmCon p{
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
  .parEduPag .pmConInn p{
    -webkit-line-clamp: unset;
  }
  .parEduPag .readMore{
    float: right;
  }
  .parEduPag .pmRecBlo a{
    border-left: 3px solid var(--primary);
    padding: 10px;
    background-color: var(--secondary);
    display: block;
    margin-bottom: 15px;
  }

/* Client Feedback */
  .cliFedPag .tesSlick{
    background-color: var(--secondary);
    padding-top: 110px;
    padding-bottom: 110px;
    margin-top: 0;
  }
  .cliFedPag .testimanialSli{
    margin-bottom: 0;
  }
  .cliFedPag .cfpVid{
    margin-bottom: 90px;
  }
  .cliFedPag .youtube16{
    margin-bottom: 24px;
  }
  .cliFedPag .aaa{}
  .cliFedPag .aaa{}

/* Contact US */
  .conUsPag .gooMap{
    margin-top: 110px;
  }
  .conUsPag .innBanner{
    margin-bottom: 110px;
  }
  .conUsPag .cupForm{
    background-color: var(--secondary);
    padding: 25px;
    border-radius: 11px;
  }
  .conUsPag .cupForm .form-control,
  .conUsPag .cupForm .form-select{
    margin-bottom: 25px;
  }
  .conUsPag .cupForm .btn-primary{
    display: block;
    width: 100%;
    padding: 11px;
    color: var(--secondary);
  }
  .cupForm .btn-primary:hover{
    background-color: rgb(6 171 182 / 70%);
  }
  .conUsPag .cup{
    display: flex;
    margin-bottom: 20px;
    font-family: 'Noto Serif';
    font-style: italic;
    font-size: 17px;
    align-items: center;
    word-break: break-word;
  }
  .conUsPag .cup svg{
    font-size: 33px;
    padding-top: 3px;
    color: var(--primary);
    margin-right: 13px;
  }

/* Job Vacancies */
  .jobVacPag{
    margin-bottom: 110px;
  }
  .jobVacPag .jvpOpe{
    background-color: var(--secondary);
    border-left: 5px solid var(--primary);
    padding: 19px 25px;
    margin-bottom: 25px;
    border-radius: 0 7px 7px 0;
    box-shadow: 2px 3px 5px var(--greyC);
  }
  .jobVacPag .jvpOpe h5{
    font-family: 'Noto Serif';
    font-style: italic;
    font-size: 22px;
    margin-bottom: 13px;
  }
  .jobVacPag .jvpOpe p{
    margin-top: 7px;
    margin-bottom: 0;
  }
  .jobVacPag .aaa{}

/* Book Appointment */
  .fc .fc-view-harness{
    height: 535px !important;
  }
  .fc .fc-scrollgrid-section-body table,
  .fc .fc-scrollgrid-section-footer table{
    height: 500px !important;
  }
  .bokAppPag .cupForm{
    margin-bottom: 90px;
  }
  .bokAppPag .aaa{}

/* footer */
  footer{
    background-color: var(--secondary);
    padding: 70px 0 15px;
  }
  footer .fotAbout img{
    width: 150px;
    margin-bottom: 30px;
  }
  footer .fotCon{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  footer .fotCon svg{
    color: var(--primary);
    padding-right: 10px;
  }
  footer .fotLinks{
    display: flex;
    flex-direction: column;
  }
  footer .fotLinks a{
    margin-bottom: 5px;
  }
  .copyRig{
    background-color: var(--gray);
    color: #ffffff;
    font-size: 12px;
  }
  .copyRig p{
    margin-bottom: 0;
    padding: 9px 0;
  }
  .copyRig .container{
    display: flex;
    justify-content: space-between;
  }
  .copyRig a{
    color: var(--primary);
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* Only Responisve code below */
@media (max-width: 1199.9px){
  /* inner pages */
  .innBanner{
    background-attachment: unset;
  }
  /* footer */
  footer .fotCon{
    flex-wrap: wrap;
    justify-content: center;
  }
  footer .fotCon p{
    margin: 5px 9px;
  }
}
@media (max-width: 991.9px){
  /* topbar */
  .topBar .container{
    flex-wrap: wrap;
    justify-content: center;
  }
  .topBar p{
    margin: 3px 7px;
    text-align: center;
  }
  /* nav */
  .navbar-nav-scroll{max-height: unset !important;}
  .navbar-light .navbar-nav .nav-link{padding: 11px 15px;}
  .dropdown-menu[data-bs-popper]{display: none;}
  .dropdown-menu.show{
    display: block;
    background-color: transparent;
  }
  .dropdown-item{
    margin-left: 15px;
  }
  .nbRight{
    padding: 13px;
    margin-bottom: 15px;
  }
  /* banner main */
  .mainBanner .mbBgIma{
    background-attachment: scroll;
    height: 450px;
  }
  .mainBanner .mbiCon{
    text-align: center;
  }
  /* index */
  .docDetThe .ddtCon{
    margin-top: 45px;
  }
  .accConAdd .youtube16{
    margin-bottom: 45px;
  }
  .tesSlick .tsSlider{
    flex-direction: column;
  }
  .tesSlick .tsSlider img{
    width: 100%;
    margin-bottom: 35px;
    margin-right: 0;
  }
  .tesSlick .slick-dots{
    bottom: -25px;
  }
  .tesSlick .youtube16{
    margin-bottom: 25px;
  }
  .gooMap:after{
    height: 50px;
  }
  /* About Us */
  .aboUs .ddtDoc .ddList{
    margin-top: 45px;
  }
  .aboUs .auAbout p{
    text-align: justify;
  }
}/* 991px */
@media (max-width: 767.9px){
  /* index */
  .ourInno{
    padding: 110px 0;
  }
  /* banner main */
  .mainBanner .mbBgIma {
    height: 350px;
  }
  .mainBanner .mbiCon h2{
    font-size: 35px;
  }
  /* Contact us */
  .conUsPag .cupForm{
    margin-bottom: 45px;
  }
  /* job Vacancies */
  .jobVacPag .cupForm{
    margin-top: 45px;
    margin-bottom: 0;
  }
  /* footer */
  footer .fotAbout{
    text-align: center;
  }
  footer .fotCon{
    text-align: center;
    margin-top: 25px;
  }
}
@media (max-width: 575.9px){
  /* utility */
  .hideMobile{display: none;}
  .showMobile{display: block;}
  /* index */
  .docDetThe .ttl{
    font-size: 26px;
  }
  .accConAdd{
    background-color: var(--secondary);
    background-image: none;
  }
  /* footer */
  footer .fotLinks{
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
  footer .fotLinks a{
    margin: 3px 7px;
  }
}
@media (max-width: 640px){}
@media (max-width: 640px){}